





























import { Component, Vue } from "vue-property-decorator";
import Map from "./components/Map.vue";
import TamedTable from "@/components/TamedTable.vue";

@Component({
	components: { TamedTable, Map },
})
export default class App extends Vue {
	tab = "table";
	items: any[] = [];

	async mounted() {
		const [files, files2] = await Promise.all([
			fetch(`https://ark.virant.fr/tamed.php`).then((r) => r.json()),
			fetch(`https://ark.virant.fr/tamed2.php`).then((r) => r.json()),
		]);

		this.items = Array.from(Object.keys(files))
			.map((k) => files[k])
			.reduce((arr: any[], cur: any[]) => {
				arr.push(
					...cur
						.filter((c) => c.type && c.wildLevels)
						.map((d) => Object.assign(d, { map: "LostIsland" }))
				);
				return arr;
			}, [] as any[]);
		this.items.push(
			...Array.from(Object.keys(files2))
				.map((k) => files2[k])
				.reduce((arr: any[], cur: any[]) => {
					arr.push(
						...cur
							.filter((c) => c.type && c.wildLevels)
							.map((d) => Object.assign(d, { map: "Ragnarok" }))
					);
					return arr;
				}, [] as any[])
		);

		const noSexDinos = [
			"Dung Beetle",
			"Titanoboa",
			"Achatina",
			"Queen Bee",
			"Titanosaur",
		];
		this.items.forEach((item, idx, items) => {
			if (item.female) {
				items[idx].sex = "F";
			} else if (noSexDinos.includes(item.type)) {
				items[idx].sex = "X";
			} else {
				items[idx].sex = "M";
			}
		});
	}
}
